<template>
  <div>
    <sub-header
      :buttonText="$t('sales_order.create_order')"
      :handleClick="createOrder"
      :showBtn="checkPermission('salesOrder.create')"
    ></sub-header>
    <div class="flex relative flex-1 flex-col flex-grow max-h-full max-w-full">

      <vue-good-table
        :isLoading="loadingOrders"
        :columns="computedColumns" 
        :rows="rows"
        :pagination-options="{ enabled: true, mode: 'records', dropdownAllowAll: false}"
        @on-column-filter="onColumnFilter" 
        v-bind="config" 
        :totalRows="totalRows"
        @on-per-page-change="onPageChange($event, true)" 
        @on-page-change="onPageChange($event)"
        @on-selected-rows-change="selectionChanged">
      >
        <template #column-filter="{ column, updateFilters }">
          <div v-if="column.filterOptions && column.filterOptions.enabled && column.config && column.config.filter_type === 'date'">
            <DatePicker range v-model="date_range" @input="(value) => updateColumnFilters(column, value, updateFilters)" 
              :key="date_range_key" class="w-full" format="DD MMM, YYYY" value-type="timestamp"  :placeholder="due_date" />
            </div>
            <dropdown
            :ref="setFilterRef"
            reposition
            searchable
            class="filter-dropdown"
            :placeholder="$t('sales_order.dropdown_placeholder')"
            v-else-if="column.filterOptions && column.filterOptions.enabled && column.config && !['indicators', 'allocation'].includes(column.config.type)"
            :options="column.filterOptions.filterDropdownItems"
            @input="(value) => updateColumnFilters(column, value, updateFilters)"
            :value="getFilterValue(column)"
            @search="searchFilter(column, $event)"
            :limit="1"
            maxWidth="100%"
            width="100px"
            minWidth="100%"
            :config="{ label: 'name', trackBy: 'id' }"
            :multiple="false"
            :taggable="false"
            >
              <template #caret>
                <sort-icon class="fill-current" />
              </template>
            </dropdown>
            <div class="" v-else>
                <!-- // adding a blank div to remove the default template   -->
            </div>
        </template>
        <template slot="table-row" slot-scope="props">
          <div
            v-if="props.column.field === 'client_name'"
            :class="[props.row.system_default && 'default hover-text']"
            class="flex gap-3"
          >
            <router-link
              :to="{
                name: 'salesOrderInfo',
                params: { id: props.row.id, client_id: props.row.client_id },
              }"
              class="text-primary whitespace-nowrap cursor-pointer hover:text-dvbrandhoveron"
              >{{ props.row.client_name }}</router-link
            >
          </div>
          <div v-if="props.column.field == 'order_number'" class="text-center">
            {{ props.row.order_number }}
          </div>
          <div
            v-if="props.column.field == 'order_reference'"
            class="text-center"
          >
            {{ props.row.order_reference }}
          </div>
          <div v-if="props.column.field == 'created_at'" class="text-center">
            {{ format(new Date(props.row.created_at), "dd LLL yyyy") }}
          </div>
          <div v-if="props.column.field == 'total_amount'" class="text-center">
            {{ props.row.total_amount }}
          </div>
          <div
            v-if="props.column.field == 'payment_status'"
            class="flex justify-center items-center"
          >
            <div v-if="props.row.payment_status == 'paid'">
              <font-awesome-icon class="items-center" icon="check" />
            </div>
          </div>
          <div
            v-if="props.column.field == 'status'"
            class="text-center capitalize"
          >
            <span :class="getStatusColor(props.row.status)">{{
              props.row.status
            }}</span>
          </div>
        </template>
        <template #loadingContent>
          <div class="w-full py-10 justify-center flex">
            <Loader />
          </div>
        </template>
      </vue-good-table>
      <button 
        v-if="isFilters"
          class=" cursor-pointer py-1 px-3 bg-gray-100 hover:bg-gray-200 rounded-md text-xs text-black flex justify-center items-center font-semibold absolute z-50 left-2" :class="isSelectionChanged ? 'top-14' : 'top-2' " @click="clearFilters">
          {{ $t('actions.clear_filters') }}
      </button>
    </div>
  </div>
</template>

<script>
import SubHeader from "@/components/SubHeader";
import Loader from "@shared/loader";
import { getSalesOrders } from "./services";
import dropdown from "@shared/components/dropdown-base";
import { onlyUnique } from "@shared/plugins/utils.js";
import { uuid } from "vue-uuid";
import { DateTime } from "luxon";
import DatePicker from "vue2-datepicker";
import sortIcon from "@shared/assets/svg/dropdown-double.svg";
import { format , parse } from "date-fns";
import { FormatDateNew } from "@/plugins/functions";
import { checkPermission } from "@shared/utils/functions";


export default {
  name: "orders-list",
  data: function () {
    return {
      columns: [
        {
          field: "client_name",
          config: {
            filter: true,
            alignCenter: true,
            field_type: 'action_button',
          },
        },
        {
          field: "order_number",
          config: {
            filter: true,
            alignCenter: true,
            field_type: 'action_button',
          },
        },
        {
          field: "order_reference",
        },
        {
          field: "created_at",
          config: {
            filter: true,
            alignCenter: true,
            field_type: 'show_date',
          },
        },
        {
          field: "total_amount",
          config: {
            filter: true,
            alignCenter: true,
            field_type: 'action_button',
          },
        },
        {
          field: "payment_status",
        },
        {
          field: "status",
        },
      ],
      params: {
        page: 1,
        limit: 10,
      },
      totalRows: 0,
      filters: {},
      serverParams: {},
      searchLoading:{},
      date_range: [],
      filterRefs: [],  
      filterOptionsAsync: {}, 
      filterOptions: {}, 
      isSelectionChanged: false,
      rows: [],
      loadingOrders: false,
    };
  },
  components: {
    SubHeader,
    Loader,    
    dropdown,
    sortIcon,
    DatePicker,
  },
  async mounted() {
    this.loadingOrders = true;
    await this.setOrders();
    this.filterOptions = this.columnFilterOptions
    this.loadingOrders = false;
  },
  computed: { 
    isFilters() {
      return this.serverParams && Object.entries(this.serverParams).length;
    },
    columnFilterOptions() {
      return this.getFilterOptions(this.columnOption, this.rows);
    },
    columnOption() {
      return this.columns.map((el) => ({
        ...el,
        config: 
        {
          ...el.config,
          isLoading: !!this.searchLoading[el.field] || false,
        },
      }));
    },
    computedColumns() {
      // use option list from server if it is:
      const options = this.filterOptions;
      // had to check for empty object
      if (options && Object.entries(options).length) {
        return this.columns.map((col) => {
          const filtered = col.config?.filter ?? true;
          if (col.prefetchedOptions && col.prefetchedOptions.length) {
            return {
              ...col,
              label: this.$t(`sales_order.orders_list_columns.${col.field}`),
              filterOptions: {
                enabled: filtered,
                filterDropdownItems: col.prefetchedOptions,
              },
            };
          } else {
            return {
              ...col,
              label: this.$t(`sales_order.orders_list_columns.${col.field}`),
              filterOptions: {
                enabled: filtered,
                filterDropdownItems: options[col.field],
              },
            };
          }
        });
      } else {
        // TODO remove/rework default filters
        return this.columns.map((col) => {
          const filtered = col.config?.filter ?? true;
          return {
            ...col,
            label: this.$t(`sales_order.orders_list_columns.${col.field}`),
            filterOptions: filtered
              ? {
                enabled: true,
                placeholder: "All",
                filterDropdownItems: this.rows
                  .filter((row) => row[col.field])
                  .map((row) => ({ id: row.field_type_id ?? uuid.v4(), name: row[col.field] }))
                  .filter(onlyUnique),
              }
              : undefined,
          };
        });
      }
    }
  },
  methods: {
    checkPermission,
    format,
    FormatDateNew,
    async setOrders() {
      const filterParams = this.makeFilterParams();
      let qParams
      qParams = new URLSearchParams(this.params);
      if (filterParams) {
        qParams = new URLSearchParams({...this.params, ...filterParams});
      }
      const { data } = await getSalesOrders(qParams);
      this.rows = data.data;
      // this.rows = this.rows?.map(el => {
      //   return {
      //     ...el,
      //     created_at: FormatDateNew(new Date(el.created_at))
      //   }
      // })
      this.totalRows = data?.page.total;
    },
    formatDate(date) {
      if (date) {
        const parsedDate = parse(date, 'dd MMM yyyy', new Date());
        return format(new Date(parsedDate), "yyyy-MM-dd");
      }
    },
    makeFilterParams() {
      let filterParams = {};
      if (this.serverParams) {
        Object.keys(this.serverParams).map((key) => {
          if (key == 'created_at') {
            filterParams[key] = this.serverParams[key] ? this.serverParams[key]["label"] : delete filterParams[key];
          } else {
            filterParams[key] = this.serverParams[key] ? this.serverParams[key]["name"] : delete filterParams[key];
          }
          if (this.serverParams[key]["name"] === undefined) {
            delete filterParams[key];
          }
        });
      }
      return filterParams;
    },
    createOrder() {
      this.$router.push({ name: "salesOrderInfo" });
    },
    getStatusColor(status) {
      if (status == "pending approval") {
        return "text-yellow-500";
      }
      if (status == "rejected") {
        return "text-red-500";
      }
      if (status == "draft") {
        return "text-blue-500";
      }
      if (status == "approved") {
        return "text-green-400";
      }
    },
    async clearFilters() {
      this.serverParams = null;
      await this.setOrders();
    },
    async onColumnFilter({ columnFilters }) {
      this.params.page = 1;
      this.serverParams = columnFilters ? { ...columnFilters } : null;
      await this.setOrders();
    },
    selectionChanged(e) {
      // console.log('e :>> ', e);
      if (e) {
          this.isSelectionChanged = e.selectedRows && e.selectedRows.length !== 0;
          this.$emit('on-selected-rows-change', e.selectedRows)
      }
    },
    getFilterOptions(columns, row) {
      return columns.reduce((result, curr) => {
        result[curr.field] = row
          .filter((row) => row[curr.field])
          .map((row) => row[curr.field])
          .filter(onlyUnique)
          .map((el) => (
            { 
              id: uuid.v4(), name: el 
            }));
            if (curr?.field == 'created_at') {
              result[curr.field] = result[curr.field]?.map(el => {
                return {
                  ...el,
                  name: FormatDateNew(new Date(el.name)),
                  label: el.name
                }
              })
            }
            return result;
          }, {});
        // if (curr?.field == 'created_id') {
        // } else {
        //   result[curr.field] = row
        //     .filter((row) => row[curr.field])
        //     .map((row) => row[curr.field])
        //     .filter(onlyUnique)
        //     .map((el) => ({ id: uuid.v4(), name: el }));
        //   return result;
        // }, {})
        // return columns.reduce((result, curr) => {
      // }
    },
    updateColumnFilters(column, value /* , callback */) {
      let filterValObj = {
        ...this.serverParams,
        [column.query_key || column.field]: value ? {
          ...value,
          field_type: column.field_type,
        } : null,
      };
      if (!filterValObj[column.query_key || column.field]) {
        delete filterValObj[column.query_key || column.field];
      }
      if (column.query_key && ["from_due_date", "from_created_at"].includes(column.query_key)) {
        let date = DateTime.fromISO(value.name).toISODate()
        if (date == null) {
          const parsedDate = parse(value.name, 'd MMM yyyy', new Date());
          date = format(parsedDate, 'yyyy-MM-dd');
          if (filterValObj.from_created_at) {
            filterValObj.from_created_at.name = date.toString().substring(10, 0)
          } else {
            filterValObj.from_due_date.name = date.toString().substring(10, 0)
          }
        }
        Object.assign(filterValObj, {
          [`to_${column.field}`]: {
            id: date.ts,
            name: date.toString().substring(10, 0),
            field_type: column.field_type
          },
        });
      }
      if (column.config && /* column.config.query_keys && */ column.config.filter_type === 'date') { // uncommment when you want to add customkeys for backend filters
        let [start, end] = value.map(el => DateTime.fromJSDate(new Date(el)))
        Object.assign(filterValObj, {
          [column.config.query_keys['start']]: {
            id: start.ts,
            name: value[0] ? start.toString() : null,
            field_type: column.field_type
          },
        })
        Object.assign(filterValObj, {
          [column.config.query_keys['end']]: {
            id: end.ts,
            name: value[1] ? end.toString() : null,
            field_type: column.field_type
          },
        })
      }
      this.onColumnFilter( {
        columnFilters: filterValObj,
      });
    },
    async onPageChange(data, resetPage = false) {
      if (resetPage) {
        this.params.page = 1;
      } else this.params.page = data.currentPage;
      this.params.limit = data.currentPerPage;
      await this.setOrders();
    }, 
    searchFilter(column, value) {
      this.$emit("search-filter", { column, value });
    },
    setFilterRef(el) {
      if (el) {
          this.filterRefs.push(el);
      }
    },
    getFilterValue(col) {
      // isFilters ? filters[column.field] : null
      if (this.isFilters) {
        return this.serverParams[col.field] ?? this.serverParams[col.query_key];
      } else return null;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@shared/assets/mixins/mixins.scss";
@include tableComponent;
</style>
